import { Injectable, NgZone, Optional, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Alert } from './alert.model';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AlertService {
    private alertId = 0;
    private alerts: Alert[] = [];
    private readonly timeout = 5_000;

    constructor(
        private sanitizer: DomSanitizer,
        private ngZone: NgZone,
        @Optional() private translateService: TranslateService
    ) {}

    get(): Alert[] {
        return this.alerts;
    }

    success(msg: string, params?: any) {
        this.addAlert({
            type: 'success',
            msg,
            params,
            timeout: this.timeout
        });
    }

    error(msg: string, params?: any) {
        this.addAlert({
            type: 'danger',
            msg,
            params,
            timeout: this.timeout
        });
    }

    httpError(error: HttpErrorResponse, msg?: string, params?: any) {
        if (error.error && error.error.detail) {
            this.error(error.error.detail);
            return;
        }
        if (error.error && error.error.details && !msg) {
            this.error(error.error.details);
            return;
        }

        if (msg) {
            this.error(msg, params);
            return;
        }
        this.error('error.global');
    }

    warning(msg: string, params?: any) {
        this.addAlert({
            type: 'warning',
            msg,
            params,
            timeout: this.timeout
        });
    }

    info(msg: string, params?: any) {
        this.addAlert({
            type: 'info',
            msg,
            params,
            timeout: this.timeout
        });
    }

    private addAlert(alertOptions: Alert): void {
        alertOptions.id = this.alertId++;
        if (alertOptions.msg) {
            alertOptions.msg = this.translateService.instant(alertOptions.msg, alertOptions.params);
        }
        this.alerts.push(this.factory(alertOptions));
        if (!!alertOptions.timeout) {
            this.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    this.ngZone.run(() =>
                        this.alerts.splice(
                            this.alerts.findIndex(el => el.id === alertOptions.id),
                            1
                        )
                    );
                }, alertOptions.timeout);
            });
        }
    }

    private factory(alertOptions: Alert): Alert {
        return {
            id: alertOptions.id,
            type: alertOptions.type,
            msg: this.sanitizer.sanitize(SecurityContext.HTML, alertOptions.msg),
            close: (id, alerts) =>
                alerts.splice(
                    alerts.findIndex(el => el.id === id),
                    1
                )
        };
    }
}

import { HttpParams } from '@angular/common/http';
import { hasValue } from './general-util';

export const createRequestOption = (req?: any): HttpParams => {
    let options: HttpParams = new HttpParams();
    if (req) {
        Object.keys(req).forEach(key => {
            if (key !== 'sort') {
                options = options.set(key, req[key]);
            }
        });
        if (req.sort) {
            req.sort.forEach(val => {
                options = options.append('sort', val);
            });
        }
    }
    return options;
};

export function createParams<T extends object>(params?: T): HttpParams {
    if (!params) {
        return null;
    }

    let options: HttpParams = new HttpParams();
    Object.keys(params).forEach(key => {
        if (!hasValue(params[key])) {
            return;
        }
        options = options.set(key, String(params[key]));
    });
    return options;
}

import { FeatureCode } from 'app/shared/model/feature-codes';
import { CanActivateFn, CanMatchFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { SessionStorageService } from 'ngx-webstorage';
import { previousUrl } from 'app/shared/constants/storage.constants';
import { AuthStore } from './auth.store';
import { filterNil } from 'app/shared/util/general-util';

export const isLoggedOut: CanMatchFn = () => inject(AuthStore).isLoggedIn$.pipe(map(val => !val));

export const isLoggedIn: CanMatchFn = (_, segments) => {
    const router = inject(Router);
    const sessionStorage = inject(SessionStorageService);
    return inject(AuthStore).isLoggedIn$.pipe(
        map(val => {
            if (!val && !!segments.length) {
                sessionStorage.store(previousUrl, router.getCurrentNavigation().extractedUrl.toString());
                return router.createUrlTree(['/']);
            }
            return val;
        })
    );
};

export function hasRouteAuthority(module: FeatureCode, alternativeModule?: FeatureCode): CanActivateFn {
    return () => {
        const authStore = inject(AuthStore);
        return authStore.account$.pipe(
            filterNil(),
            map(() =>
                alternativeModule
                    ? authStore.hasAnyAuthorityAlternative([module, alternativeModule])
                    : authStore.hasAnyAuthority(module, null)
            )
        );
    };
}

import { createActionGroup, createFeature, createReducer, emptyProps, on, props } from '@ngrx/store';

interface SharedState {
    chatUnseen: number;
}

const initialState: SharedState = {
    chatUnseen: 0
};

export const chatCounterActions = createActionGroup({
    source: 'Chat Counter',
    events: {
        initCounterSuccess: props<{ unseen: number }>(),
        resetCounter: emptyProps(),
        increaseCounter: emptyProps(),
        decreaseCounter: props<{ read: number }>()
    }
});

const reducer = createReducer(
    initialState,
    on(chatCounterActions.initCounterSuccess, (s, { unseen }) => ({
        ...s,
        chatUnseen: unseen
    })),
    on(chatCounterActions.resetCounter, s => ({ ...s, chatUnseen: 0 })),
    on(chatCounterActions.increaseCounter, s => ({ ...s, chatUnseen: s.chatUnseen + 1 })),
    on(chatCounterActions.decreaseCounter, (s, { read }) => ({
        ...s,
        chatUnseen: s.chatUnseen - read >= 0 ? s.chatUnseen - read : 0
    }))
);

export const sharedFeature = createFeature({ name: 'shared', reducer });

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
    type: 'DEV',
    google_analytics_code: 'UA-135883541-1',
    GMapsApiKey: 'AIzaSyD4FGgR0byTfcvDVot6RiNnA_376QqoCiw',
    entryPoint: 'https://closeout-r1.enetelsolutions.com',
    newControlPanel: 'control-panel/',
    // newControlPanel: 'http://localhost:8082/control-panel/',
    notificationService: 'notification-service/',
    // notificationService: 'http://localhost:8085/notification-service/',
    elasticSearchService: 'elasticsearch-service/',
    // elasticSearchService: 'http://localhost:8084/elasticsearch-service/',
    projectAdminService: 'project-administration-service/',
    // projectAdminService: 'http://localhost:8083/project-administration-service/',
    billingService: 'billing-service/',
    scheduledService: 'scheduled-service/'
    // scheduledService: 'http://localhost:8088/scheduled-service/'
};

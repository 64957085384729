import { inject } from '@angular/core';
import { HttpErrorResponse, HttpInterceptorFn, HttpRequest } from '@angular/common/http';
import { environment } from 'environment';
import { AuthStore } from 'app/core/auth/auth.store';
import { catchError, switchMap, take } from 'rxjs/operators';
import { throwError } from 'rxjs';

export const authInterceptor: HttpInterceptorFn = (req, next) => {
    // tenant switch
    if (req.url.includes('auth/oauth2/switch-tenant')) {
        return next(updateRequest(req, false));
    }

    // login, forgot password, registration
    if (req.url.startsWith(environment.entryPoint)) {
        return next(req);
    }

    // openVidu
    if (req.url.includes('api/sessions') || req.url.includes('/api/tokens')) {
        return next(req);
    }

    // monolith and microservices API || get all user's tenants, adding user on tenant, changing user info, password change
    if (req.url.includes('api/') || req.url.includes('regions/registration')) {
        return next(updateRequest(req, !req.url.includes('localhost')));
    }

    return next(req);
};

function updateRequest(req: HttpRequest<unknown>, updateUrl: boolean): HttpRequest<unknown> {
    const token = inject(AuthStore).getTenantToken();
    return req.clone({
        setHeaders: { Authorization: `Bearer ${token.access_token}` },
        url: updateUrl && !req.url.includes('localhost') ? `${token.base_uri}/${req.url}` : req.url
    });
}

export const authExpiredInterceptor: HttpInterceptorFn = (req, next) => {
    const authStore = inject(AuthStore);
    return next(req).pipe(
        catchError((err: HttpErrorResponse) => {
            if (err.status === 401) {
                authStore.refreshToken();
                return authStore.token$.pipe(
                    take(1),
                    switchMap(token =>
                        next(
                            req.clone({
                                setHeaders: { Authorization: `Bearer ${token.access_token}` }
                            })
                        )
                    )
                );
            }
            return throwError(() => err);
        })
    );
};

import { environment } from 'environment';
import { inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

export function attachGoogleAnalytics(): void {
    const document = inject(DOCUMENT);

    const firstScript = document.createElement('script');
    firstScript.async = true;
    firstScript.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.google_analytics_code;

    const secondScript = document.createElement('script');
    secondScript.innerHTML =
        'window.dataLayer = window.dataLayer || [];\n' +
        'function gtag(){dataLayer.push(arguments);}\n' +
        `gtag('js', new Date());\n` +
        `gtag('config', '${environment.google_analytics_code}');`;

    document.head.insertBefore(secondScript, document.head.firstChild);
    document.head.insertBefore(firstScript, document.head.firstChild);
}

export function sendUrlToGoogleAnalytics(): void {
    inject(Router)
        .events.pipe(
            filter((e): e is NavigationEnd => e instanceof NavigationEnd),
            filter(e => !!e.urlAfterRedirects)
        )
        .subscribe(event => gtag('config', environment.google_analytics_code, { page_path: event.urlAfterRedirects }));
}

export function logGAEvent(event: string, category: string, label: string, value: number | string): void {
    gtag('event', event, {
        event_category: '(WEB) ' + category,
        event_label: label,
        value
    });
}

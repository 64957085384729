import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ChatDTO, RemoveFromGroupPayload, SendMessageResponse } from 'app/chat/chat.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environment';
import { createParams } from 'app/shared/util/request-util';
import { Pageable, PageableParams } from 'app/shared/model/pageable.model';
import { IUserBasic } from 'app/shared/model/user-basic';
import { ChatMessageDTO, ChatMessagePayload } from 'app/chat/chat-messages/chat-messages.model';

@Injectable({ providedIn: 'root' })
export class ChatService {
    private http = inject(HttpClient);
    private url = environment.notificationService + 'api/chat';

    getChatList(filter: string, pageableParams: PageableParams): Observable<Pageable<ChatDTO>> {
        const params = createParams({ filter, ...pageableParams });
        return this.http.get<Pageable<ChatDTO>>(this.url, { params });
    }

    getTotalUnseen(): Observable<number> {
        return this.http.get<number>(`${this.url}/unseen`);
    }

    resetUnseen(chatId: number): Observable<void> {
        const params = createParams({ chatId });
        return this.http.put<void>(`${this.url}/reset_unseen`, null, { params });
    }

    getChatCandidates(filter: string, pageableParams: PageableParams): Observable<Pageable<IUserBasic>> {
        const params = createParams({ filter, ...pageableParams });
        return this.http.get<Pageable<IUserBasic>>(`${this.url}/candidates`, { params });
    }

    getChatMessages(chatId: number, pageableParams: PageableParams): Observable<Pageable<ChatMessageDTO>> {
        const params = createParams({ chatId, ...pageableParams });
        return this.http.get<Pageable<ChatMessageDTO>>(`${this.url}/messages/get`, { params });
    }

    sendChatMessage(chatMessage: ChatMessagePayload): Observable<SendMessageResponse> {
        return this.http.post<SendMessageResponse>(`${this.url}/messages/send`, chatMessage);
    }

    editChatMessage(messageId: number, messageText: string): Observable<void> {
        const params = createParams({ messageId, messageText });
        return this.http.put<void>(`${this.url}/messages/edit`, null, { params });
    }

    deleteChatMessage(messageId: number): Observable<void> {
        const params = createParams({ messageId });
        return this.http.delete<void>(`${this.url}/messages/delete`, { params });
    }

    createGroup(participants: number[]): Observable<ChatDTO> {
        const params = createParams({ participants });
        return this.http.post<ChatDTO>(`${this.url}/group/create`, null, { params });
    }

    addToGroup(chatId: number, participants: number[]): Observable<void> {
        const params = createParams({ participants, chatId });
        return this.http.post<void>(`${this.url}/group/add`, null, { params });
    }

    removeFromGroup(payload: RemoveFromGroupPayload): Observable<void> {
        return this.http.put<void>(`${this.url}/group/remove`, payload);
    }

    renameGroup(chatId: number, groupName: string): Observable<ChatDTO> {
        const params = createParams({ chatId, groupName });
        return this.http.put<ChatDTO>(`${this.url}/group/rename`, null, { params });
    }

    leaveGroup(chatId: number): Observable<void> {
        const params = createParams({ chatId });
        return this.http.post<void>(`${this.url}/group/leave`, null, { params });
    }
}

import { HttpResponse } from '@angular/common/http';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { filter } from 'rxjs/operators';

export function hasValue<T>(x: T): boolean {
    return x !== null && x !== undefined;
}

export function filterNil<T>() {
    return filter<T>(value => hasValue(value));
}

export function applyTimezoneOffsetToDate(date: Date): Date {
    if (date) {
        return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    }
}

// Takes a date object and returns a string representation in YYYY-MM-DD format
export function dateToYYYYMMDD(date: Date): string {
    return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
}

export function downloadFromBlob(res: HttpResponse<Blob>): void {
    let fileName = res.headers.get('filename') ?? res.headers.get('content-disposition');

    if (fileName) {
        // Check if `filename*=` format is used
        const filenameMatch = fileName.match(/filename\*=[^;]+/);

        if (filenameMatch) {
            // Extract and decode the filename, replacing + with spaces
            fileName = decodeURIComponent(filenameMatch[0].split('=')[1].replace(/\+/g, ' '));
        } else {
            // Fallback for `filename="..."` format or direct filename
            fileName = fileName.split('="')[1]?.replace(/"/g, '') ?? fileName;
            fileName = decodeURIComponent(fileName.replace(/\+/g, ' ')); // Decode and replace + with space
        }

        const element = document.createElement('a');
        element.href = URL.createObjectURL(res.body);
        element.download = fileName;
        element.click();
        URL.revokeObjectURL(element.href);
        element.remove();
    }
}

export function prettyPrintNumberOfBytes(numberOfBytes: number): string {
    if (numberOfBytes === 0) {
        return '0 Bytes';
    }

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    const i = Math.floor(Math.log(numberOfBytes) / Math.log(k));

    return Math.round(numberOfBytes / Math.pow(k, i)) + ' ' + sizes[i];
}

// Normalises mouse wheel speed across different browsers, written by Facebook team in 2015
export function normalizeWheel(/*object*/ event): { pixelX: number; pixelY: number; spinY: number; spinX: number } {
    const PIXEL_STEP = 10;
    const LINE_HEIGHT = 40;
    const PAGE_HEIGHT = 800;
    let sX = 0,
        sY = 0, // spinX, spinY
        pX: number,
        pY: number; // pixelX, pixelY

    // Legacy
    if ('detail' in event) {
        sY = event.detail;
    }
    if ('wheelDelta' in event) {
        sY = -event.wheelDelta / 120;
    }
    if ('wheelDeltaY' in event) {
        sY = -event.wheelDeltaY / 120;
    }
    if ('wheelDeltaX' in event) {
        sX = -event.wheelDeltaX / 120;
    }

    // side scrolling on FF with DOMMouseScroll
    if ('axis' in event && event.axis === event.HORIZONTAL_AXIS) {
        sX = sY;
        sY = 0;
    }

    pX = sX * PIXEL_STEP;
    pY = sY * PIXEL_STEP;

    if ('deltaY' in event) {
        pY = event.deltaY;
    }
    if ('deltaX' in event) {
        pX = event.deltaX;
    }

    if ((pX || pY) && event.deltaMode) {
        if (event.deltaMode === 1) {
            // delta in LINE units
            pX *= LINE_HEIGHT;
            pY *= LINE_HEIGHT;
        } else {
            // delta in PAGE units
            pX *= PAGE_HEIGHT;
            pY *= PAGE_HEIGHT;
        }
    }

    // Fall-back if spin cannot be determined
    if (pX && !sX) {
        sX = pX < 1 ? -1 : 1;
    }
    if (pY && !sY) {
        sY = pY < 1 ? -1 : 1;
    }

    return {
        spinX: sX,
        spinY: sY,
        pixelX: pX,
        pixelY: pY
    };
}

export function scrollToMiddle(el: HTMLElement, container: HTMLElement): void {
    if (!el || !container) {
        return;
    }
    const elTop = el.offsetTop;
    const elBottom = elTop + el.clientHeight;

    const containerTop = container.scrollTop;
    const containerBottom = containerTop + container.clientHeight;

    const isFullyVisible = elTop >= containerTop && elBottom <= containerBottom;

    if (!isFullyVisible) {
        el.scrollIntoView({ block: 'center' });
    }
}

export function isStatUpdated(oldStat: number, newStat: number): boolean {
    if (oldStat && newStat && oldStat === newStat) {
        return false;
    }
    return !(!oldStat && !newStat);
}

export function getUniqueItemsByProperty<T>(items: T[], property: keyof T): T[] {
    return items.filter((val, index, array) => index === array.findIndex(el => el[property] === val[property]));
}

export function toggleSelection<T>(checked: boolean, arr: T[], item: T): T[] {
    return !checked ? arr.filter(id => id !== item) : [...arr, item];
}

export function haveIntersection<T>(first: T[], second: T[]): boolean {
    if (!first || !second || !first.length || !second.length) {
        return false;
    }
    return first.some(el => second.includes(el));
}

export function resetScrollDirective(scrollDirective: InfiniteScrollDirective): void {
    // When we reduce the container div size the infinite-scroll will no longer fire it's 'scrolled' event so we need to restart it
    if (scrollDirective) {
        scrollDirective.destroyScroller();
        scrollDirective.setup();
    }
}

export function getPercentage(status: number, total: number, digits = 1): string {
    return !!total
        ? ((status / total) * 100).toLocaleString('EN', {
              maximumFractionDigits: digits
          })
        : '0';
}

export function areArraysDifferent<T extends string | number>(arr1: T[], arr2: T[]): boolean {
    if (arr1?.length !== arr2?.length) {
        return true;
    }
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    return !sortedArr1.every(el => sortedArr2.includes(el));
}

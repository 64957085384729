import { Injectable } from '@angular/core';
import { defer, EMPTY, exhaustMap, Subject, timer } from 'rxjs';
import { catchError, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { Credentials, AccessToken, RegionTokens, TenantTokenVM } from './auth.model';
import { environment } from 'environment';
import jwtDecode from 'jwt-decode';
import { Router } from '@angular/router';
import { UserAccount } from '../user/account.model';
import { DateTime } from 'luxon';
import { Loader } from '@googlemaps/js-api-loader';
import { FeatureCode } from 'app/shared/model/feature-codes';
import { filterNil, haveIntersection } from 'app/shared/util/general-util';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import { AuthService } from './auth.service';
import { LANG_KEY_EN } from 'app/app.constants';
import { AlertService } from 'app/shared/alert/alert.service';

interface AuthStoreState {
    token: TenantTokenVM;
    isLoggedIn: boolean;
    account: UserAccount;
    langKey: string;
    tenants: TenantTokenVM[];
    deviceId: string;
}

interface StoredState {
    token: TenantTokenVM;
    storedAt: number;
    expiresAt: number;
}

@Injectable({ providedIn: 'root' })
export class AuthStore extends ComponentStore<AuthStoreState> {
    private readonly timeoutFactor = 0.8;
    private readonly tokenKey = 'auth-token';
    private readonly deviceIdKey = 'deviceId';
    private cancelTokenTimer$ = new Subject<void>();

    isLoggedIn$ = this.select(s => s.isLoggedIn);
    tenantTokens$ = this.select(s => s.tenants);
    account$ = this.select(s => s.account);
    langKey$ = this.select(s => s.langKey);
    token$ = this.select(s => s.token).pipe(filterNil());
    tenantChanged$ = this.select(this.token$, token => token.tenant_id);
    mapApiLoaded$ = defer(() =>
        new Loader({
            apiKey: environment.GMapsApiKey,
            libraries: ['geometry']
        }).load()
    ).pipe(shareReplay(1));

    constructor(
        private authService: AuthService,
        private sessionStorage: SessionStorageService,
        private localStorage: LocalStorageService,
        private router: Router,
        private alertService: AlertService
    ) {
        super();
    }

    login(credentials: Credentials) {
        return this.authService
            .login(credentials)
            .pipe(tap(data => this.patchState({ isLoggedIn: true, tenants: this.mapRegionsToTenants(data) })));
    }

    sdso = this.effect<boolean>(trigger$ =>
        trigger$.pipe(
            exhaustMap(() =>
                this.authService
                    .sdso({
                        source: 'WEB',
                        deviceId: this.getDeviceId()
                    })
                    .pipe(catchError(() => EMPTY))
            )
        )
    );

    checkDeviceId = this.effect<string>(deviceId$ =>
        deviceId$.pipe(
            tap(deviceId => {
                if (deviceId !== this.getDeviceId()) {
                    this.logout();
                    this.alertService.error('global.messages.error.sdso');
                }
            })
        )
    );

    clearTenantTokens() {
        this.patchState({ tenants: [] });
    }

    logout(): void {
        this.cancelTokenTimer$.next();
        this.sessionStorage.clear(this.tokenKey);
        this.setState(s => ({
            token: null,
            tenants: [],
            isLoggedIn: false,
            account: null,
            langKey: LANG_KEY_EN,
            deviceId: s.deviceId
        }));
        this.router.navigate(['']).then();
    }

    refreshToken = this.effect<void | 0>(trigger$ =>
        trigger$.pipe(
            exhaustMap(() => {
                const token = this.getTenantToken();
                return this.authService.refreshToken(token).pipe(
                    tapResponse(
                        res =>
                            this.saveToken({
                                ...res[0],
                                base_uri: token.base_uri
                            }),
                        () => this.logout()
                    )
                );
            })
        )
    );

    saveToken(token: TenantTokenVM): void {
        const storedAt = Date.now();
        const expiresAt = storedAt + this.decodeAccessToken(token.access_token)['token-validity'] * 1000;
        this.sessionStorage.store(this.tokenKey, { token, storedAt, expiresAt } satisfies StoredState);
        this.patchState({ token });
        this.setupSilentRefresh(this.calcTimeout(expiresAt, storedAt));
    }

    private setupSilentRefresh(delay: number) {
        this.cancelTokenTimer$.next();
        this.refreshToken(timer(delay).pipe(takeUntil(this.cancelTokenTimer$)));
    }

    private mapRegionsToTenants(regions: RegionTokens[]): TenantTokenVM[] {
        const tenants: TenantTokenVM[] = [];
        regions.forEach(region => region.tokens.forEach(el => tenants.push({ ...el, base_uri: region.base_uri })));
        return tenants;
    }

    updateLanguage(langKey: string) {
        this.patchState({ langKey });
    }

    decodeAccessToken(token = this.getTenantToken().access_token): AccessToken {
        return jwtDecode<AccessToken>(token);
    }

    getUserId(): number {
        return this.decodeAccessToken().user;
    }

    getTenantToken(): TenantTokenVM {
        return this.get().token;
    }

    getTimeZone(savedToken = this.getTenantToken()): string {
        return !savedToken ? DateTime.now().zoneName : this.decodeAccessToken(savedToken.access_token)['time-zone'];
    }

    getStorageKey(feature: string): string {
        const accessToken = this.decodeAccessToken();
        return `${accessToken.tenant}-${accessToken.user}-${feature}`;
    }

    vqaFeature(): boolean {
        const schema = this.decodeAccessToken().tenant;
        switch (environment.type) {
            case 'DEV':
                return (
                    schema === 'closeout_dev_02' ||
                    schema === 'closeout_sloba' ||
                    schema === 'closeout_r1_1' ||
                    schema === 'closeout_test'
                );
            case 'QA':
                return schema === 'closeout_qa0_01' || schema === 'closeout_qa0_02';
            case 'PROD':
                return (
                    schema === 'closeout_rn' ||
                    schema === 'closeout_test' ||
                    schema === 'closeout_nt' ||
                    schema === 'closeout_asp' ||
                    schema === 'closeout_av' ||
                    schema === 'closeout_zteaut' ||
                    schema === 'closeout_etv' ||
                    schema === 'closeout_sbb'
                );
            default:
                return false;
        }
    }

    allowChatFeature(): boolean {
        return environment.type === 'DEV';
    }

    private calcTimeout(expiresAt: number, storedAt: number): number {
        const delta = (expiresAt - storedAt) * this.timeoutFactor - (Date.now() - storedAt);
        return Math.max(0, delta);
    }

    hasAnyAuthorityAlternative(requiredModules: FeatureCode[]) {
        const user = this.get().account;
        if (!user) {
            return false;
        }

        return user.modules.filter(value => requiredModules.includes(value.module)).length > 0;
    }

    hasAnyAuthority(requiredModule: FeatureCode, requiredFeature: FeatureCode[]): boolean {
        const user = this.get().account;
        if (!user) {
            return false;
        }

        const module = user.modules.find(value => value.module === requiredModule);
        if (!module) {
            return false;
        }

        if (requiredFeature) {
            return haveIntersection(module.featuresCodes, requiredFeature);
        }

        return true;
    }

    getAccount = this.effect<number>(tenantId$ =>
        tenantId$.pipe(
            filterNil(),
            switchMap(() =>
                this.authService.getAccount().pipe(
                    tapResponse(
                        account => this.patchState({ account, langKey: account.langKey }),
                        () => this.logout()
                    )
                )
            )
        )
    );

    getDeviceId() {
        return this.get().deviceId;
    }

    setInitialState() {
        const storedToken = this.sessionStorage.retrieve(this.tokenKey);
        let deviceId = this.localStorage.retrieve(this.deviceIdKey) as string;
        if (!deviceId) {
            deviceId = crypto.randomUUID();
            this.localStorage.store(this.deviceIdKey, deviceId);
        }
        this.setState({
            token: storedToken?.token,
            isLoggedIn: !!storedToken,
            account: null,
            tenants: [],
            langKey: LANG_KEY_EN,
            deviceId
        });
        if (storedToken) {
            const delay = this.calcTimeout(storedToken.expiresAt, storedToken.storedAt);
            const validity = this.decodeAccessToken()['token-validity'] * 1000;
            delay > validity * (1 - this.timeoutFactor) ? this.setupSilentRefresh(delay) : this.refreshToken();
        }
    }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { Alert } from './alert.model';
import { AlertService } from './alert.service';

@Component({
    standalone: true,
    selector: 'jhi-alert',
    template: ` <div class="alerts" *ngIf="alerts.length">
        <div *ngFor="let alert of alerts" class="toast show top center">
            <ngb-alert [type]="alert.type" (closed)="alert.close(alert.id, alerts)">
                <div [innerHTML]="alert.msg"></div>
            </ngb-alert>
        </div>
    </div>`,
    imports: [NgbAlertModule, NgForOf, NgIf],
    styleUrls: ['./alert.component.scss']
})
export class JhiAlertComponent implements OnInit, OnDestroy {
    alerts: Alert[] = [];

    constructor(private alertService: AlertService) {}

    ngOnInit() {
        this.alerts = this.alertService.get();
    }

    ngOnDestroy() {
        this.alerts = [];
    }
}
